/* global angular, config */

'use strict';

const path = 'app/procedures/';
const listTemplate = 'list.html', itemTemplate = 'item.html';
const listTemplatePath = 'app/procedures/templates/list.html';
const itemTemplatePath = 'app/procedures/templates/item.html';
const defaultController = 'ProcedureController';
const defaultServiceMethod = ':GET';

function prepareRouteParams(procedure, list = true, reloadOnSearch = null) {
    let params = {
        templateUrl: list ? listTemplatePath : itemTemplatePath,
        controller: defaultController,
        hasPermissions: [procedure.serviceName + defaultServiceMethod],
        resolve: {
            procedureParams: function () {
                return procedure;
            }
        }
    };
    if (typeof reloadOnSearch === 'boolean') {
        params.reloadOnSearch = reloadOnSearch;
    }
    return params;
}

angular.module('managerApp').config(($routeProvider) => {

    let dup = config.app.procedures.dup;
    let cim = config.app.procedures.cim;
    let ciww = config.app.procedures.ciww;
    let civi = config.app.procedures.civi;

    $routeProvider

            .when('/procedures', {
                templateUrl: path + 'procedures.html',
                controller: () => {
                }
            })

            .when('/procedures/never-handled', {
                templateUrl: path + 'templates/never.handled.procedures.html',
                controller: defaultController,
                resolve: {
                    procedureParams: function () {
                        return {
                            type:'never-handled',
                            procedures: config.app.procedures
                        };
                    }
                }
            })

//-------------------------------------------------------------- carte grise ---

            .when('/procedures/' + cim.cssClass, prepareRouteParams(cim))
            .when('/procedures/' + cim.cssClass + '/:id', prepareRouteParams(cim, false, false))
            .when('/procedures/certificat-immatriculation', prepareRouteParams(cim))
            .when('/procedures/certificat-immatriculation/:id', prepareRouteParams(cim, false, false))

//-------------------------------------------------------------- ww ---

            .when('/procedures/' + ciww.cssClass, prepareRouteParams(ciww))
            .when('/procedures/' + ciww.cssClass + '/:id', prepareRouteParams(ciww, false, false))
            .when('/procedures/certificat-immatriculation-ww', prepareRouteParams(ciww))
            .when('/procedures/certificat-immatriculation-ww/:id', prepareRouteParams(ciww, false, false))

//------------------------------------------------------------ civi ---

            .when('/procedures/' + civi.cssClass, prepareRouteParams(civi))
            .when('/procedures/' + civi.cssClass + '/:id', prepareRouteParams(civi, false, false))
            .when('/procedures/certificat-immatriculation-vi', prepareRouteParams(civi))
            .when('/procedures/certificat-immatriculation-vi/:id', prepareRouteParams(civi, false, false))

//--------------------------------------------------- declaration de cession ---
            .when('/procedures/declaration-cession', {
                templateUrl: listTemplatePath,
                controller: defaultController,
                hasPermissions: ['SERVICE_DECLARATION_CESSION:GET'],
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.dc;
                    }
                }
            })
            .when('/procedures/declaration-cession/:id', {
                templateUrl: itemTemplatePath,
                controller: defaultController,
                hasPermissions: ['SERVICE_DECLARATION_CESSION:GET'],
                reloadOnSearch: false,
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.dc;
                    }
                }
            })
//----------------------------------------------------- changement d'adresse ---
            .when('/procedures/changement-adresse', {
                templateUrl: listTemplatePath,
                controller: defaultController,
                hasPermissions: ['SERVICE_CHANGEMENT_ADRESSE:GET'],
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.cad;
                    }
                }
            })
            .when('/procedures/changement-adresse/:id', {
                templateUrl: itemTemplatePath,
                controller: defaultController,
                hasPermissions: ['SERVICE_CHANGEMENT_ADRESSE:GET'],
                reloadOnSearch: false,
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.cad;
                    }
                }
            })
//---------------------------------------------------------------- duplicata ---
            .when('/procedures/duplicata', {
                templateUrl: listTemplatePath,
                controller: defaultController,
                hasPermissions: [dup.serviceName + ':GET'],
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.dup;
                    }
                }
            })
            .when('/procedures/duplicata/:id', {
                templateUrl: itemTemplatePath,
                controller: defaultController,
                hasPermissions: [dup.serviceName + ':GET'],
                reloadOnSearch: false,
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.dup;
                    }
                }
            })
//--------------------------------------------------- declaration d'achat ---
            .when('/procedures/declaration-achat', {
                templateUrl: listTemplatePath,
                controller: defaultController,
                hasPermissions: ['SERVICE_DECLARATION_ACHAT:GET'],
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.da;
                    }
                }
            })
            .when('/procedures/declaration-achat/:id', {
                templateUrl: itemTemplatePath,
                controller: defaultController,
                hasPermissions: ['SERVICE_DECLARATION_ACHAT:GET'],
                reloadOnSearch: false,
                resolve: {
                    procedureParams: function () {
                        return config.app.procedures.da;
                    }
                }
            })
//----------------------------------------------------------- vente en ligne ---
            /*.when('/procedures/vente-en-ligne', {
             templateUrl: listTemplatePath,
             controller: 'VenteEnLigneController',
             hasPermissions: ['SERVICE_VENTE_EN_LIGNE:GET']
             })
             .when('/procedures/vente-en-ligne/:id', {
             templateUrl: path + 'vente-en-ligne/' + itemTemplate,
             controller: 'VenteEnLigneController',
             hasPermissions: ['SERVICE_VENTE_EN_LIGNE:GET']
             })*/
//------------------------------------------------------ declaration d'achat ---

            .when('/procedures/certificat-situation-administrative', {
                templateUrl: path + 'certificat-situation-administrative/' + listTemplate,
                controller: 'CertificatSituationController',
                hasPermissions: ['SERVICE_CERTIFICAT_SITUATION_ADMINISTRATIVE:GET']
            })

            .when('/procedures/certificat-situation-administrative/:id', {
                templateUrl: path + 'certificat-situation-administrative/' + itemTemplate,
                controller: 'CertificatSituationController',
                hasPermissions: ['SERVICE_CERTIFICAT_SITUATION_ADMINISTRATIVE:GET']
            })

            .when('/procedures/plaques-immatriculation', {
                templateUrl: path + 'plaques-immatriculation/' + listTemplate,
                controller: 'PlaquesImmatriculationController',
                hasPermissions: ['SERVICE_PLAQUES_IMMATRICULATION:*']
            })
            .when('/procedures/plaques-immatriculation/:id', {
                templateUrl: path + 'plaques-immatriculation/' + itemTemplate,
                controller: 'PlaquesImmatriculationController',
                hasPermissions: ['SERVICE_PLAQUES_IMMATRICULATION:*']
            });

});